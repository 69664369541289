


























































































































import Vue from "vue";
import newAxiosInstance from "@/helpers/axios-plain";
import Component from "vue-class-component";
import { Steps, Step, Button, Cell, CellGroup, Checkbox, CheckboxGroup, Swipe, SwipeItem, Icon, RadioGroup, Radio, Row, Col, Toast, Dialog, Field, Picker, Popup, Tag, Grid, GridItem, SubmitBar } from "vant";
import { Watch } from "vue-property-decorator";
import weui from "weui.js";
import "weui";
import { Route, RawLocation } from "vue-router";

const Axios = newAxiosInstance();

enum ReplaceStep {
  Init,
  Replace,
  Done
}

enum CabinetAct {
  Unlock,
  BatteryReturned
}

enum Color {
  Red = "#f00",
  Green = "#090",
  Orange = "#f82",
  Purple = "#94a",
  Black = "#222"
}

interface Act {
  act: number;
  no: number;
  battery: Battery;
  user: User;
  isIrrelevant: boolean;
}

@Component({
  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [SubmitBar.name]: SubmitBar
  },
  watch: {
    step: "onStepChange"
  }
})
export default class BatteryPlanOrderReplaceManual extends Vue {
  // ---------- data
  planOrderId = 0;
  userId = 0;
  box2Id = 0;
  manualLogId = 0;
  batteryIds = [] as number[];

  orders = [
    {
      batteryList: []
    }
  ];

  step = 0;
  maxStep = 2;
  qrCode = "";
  isUpdating = false;
  keepUpdating = false;
  mark = "";
  price = 0;
  inList = [] as Act[];
  outList = [] as Act[];
  cabinet = {} as BatteryCabinet;
  cabinetPopup = {
    show: false,
    act: -1 as CabinetAct,
    picked: { no: -1 } as Room
  };
  CabinetAct = CabinetAct;
  // ---------- lifecycle
  data() {
    return {
      planOrderId: parseInt(this.$route.params.id) || 0
    };
  }
  created() {
    this.$readyWx(this.$axios, ["scanQRCode"]);
  }
  beforeRouteLeave(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => void)) => void) {
    this.keepUpdating = false;
    next();
  }
  // ---------- functions
  scan() {
    this.$scanCode((content: string) => {
      const res = content.split("?c=");
      if (res.length == 2) {
        this.qrCode = res[1];
      } else {
        Toast.fail("二维码内容错误");
      }
    });
  }
  startManualReplace() {
    const data = { planOrderId: this.planOrderId, qrCode: this.qrCode };
    return this.$axios.post("/api/manage/startReplaceManual", data).then(e => {
      const d = e.data.data;
      this.manualLogId = d.id;
      this.userId = d.userId;
      this.box2Id = d.box2Id;
      this.batteryIds = d.batteryIds || [];
    });
  }
  getUpdate() {
    if (!this.keepUpdating) return;
    if (this.isUpdating) return;
    this.isUpdating = true;
    Axios.post("/api/manage/getReplaceManualUpdate", { key: this.box2Id }, { timeout: 0 })
      .then(e => {
        const res = e.data;
        if (res.status != 1 && res.status != 2) {
          Promise.reject();
          return;
        }
        const data = res.data;
        if (res.status == 1) {
          if (data.act == 5) {
            this.inList.push(Object.assign({}, data, { isIrrelevant: false }));
          } else if (data.act == 6) {
            if (data.battery.userId == 0) {
              // 取出有用户的电池忽略
              this.outList.push(Object.assign({}, data, { isIrrelevant: false }));
            }
          }
        }
        this.isUpdating = false;
        this.getUpdate();
      })
      .catch(() => {
        this.isUpdating = false;
        this.keepUpdating = false;
        Toast.fail("拉取换电信息时发生错误");
      });
  }
  getCabinetData() {
    return this.$axios.post("/api/manage/getReplaceManualCabinetInfo", { replaceManualLogId: this.manualLogId }).then(e => {
      const data = e.data.data;
      this.cabinet = data;
    });
  }
  switchBattery(battery: Battery) {
    const data = {
      planOrderId: this.planOrderId,
      oldBatteryIds: -1,
      newBatteryIds: battery.id,
      isFee: 0,
      mark: "sys"
    };
    const options: { value: number; label: string }[] = !this.batteryIds
      ? []
      : this.batteryIds.map(v => {
          return { value: v, label: v + "" };
        });
    options.push({ value: -1, label: "新增" });
    weui.picker(options, {
      onConfirm: (result: { value: number }[]) => {
        data.oldBatteryIds = result[0].value;
        this.$axios
          .post("/api/manage/batteryPlanOrderBatterySwitchMulti", data)
          .then(() => {
            this.batteryIds.push(battery.id);
            if (data.oldBatteryIds != -1) {
              this.batteryIds = this.batteryIds.filter(v => data.oldBatteryIds != v);
            }
            battery.userId = this.userId;
            battery.planOrderId = this.planOrderId;
          })
          .then(() => {
            this.reloadBatteries();
          });
      }
    });
  }
  // clearBattery(battery: Battery) {
  //   const data = {
  //     planOrderId: battery.planOrderId,
  //     oldBatteryIds: battery.id,
  //     newBatteryIds: -1,
  //     isFee: 0,
  //     mark: "sys"
  //   };
  //   return Dialog.confirm({
  //     message: "是否确定要清除电池归属?"
  //   }).then(() => {
  //     this.$axios
  //       .post("/api/manage/batteryPlanOrderBatterySwitchMulti", data)
  //       .then(() => {
  //         battery.userId = 0;
  //         battery.planOrderId = 0;
  //       })
  //       .then(() => {
  //         this.reloadBatteries();
  //       });
  //   });
  // }
  reloadBatteries() {
    const inBatteryIds = this.inList.map(v => v.battery.id);
    const outBatteryIds = this.outList.map(v => v.battery.id);
    const batteryIds = inBatteryIds.concat(outBatteryIds).join(",");
    if (!batteryIds) return;
    this.$axios.post("/api/manage/reloadBatteriesOnReplaceManual", { replaceManualLogId: this.manualLogId, batteryIds: batteryIds }).then(e => {
      const data = e.data.data;
      const cb = (list: Act[]) => {
        for (const act of list) {
          for (const battery of data.batteryList) {
            if (act.battery.id == battery.id) {
              act.battery = battery;
            }
          }
          for (const user of data.userList) {
            if (act.battery.userId == user.id) {
              act.user = user;
            }
          }
        }
      };
      cb(this.inList);
      cb(this.outList);
    });
  }
  save() {
    if (!this.mark) {
      Toast.fail("请填写备注");
      return;
    }
    const data = {
      replaceManualLogId: this.manualLogId,
      returnBatteryIds: this.inList.map(v => v.battery.id).join(","),
      borrowBatteryIds: this.outList.map(v => v.battery.id).join(","),
      mark: this.mark,
      fee: Math.floor(this.price * 100)
    };
    this.$axios.post("/api/manage/endReplaceManual", data).then(() => {
      Toast.success({
        message: "操作成功",
        onClose: () => {
          this.$toUrl("/web/manager/battery_plan_order_detail?exchange_order_id=" + this.planOrderId, true);
        }
      });
    });
  }
  // -------------- view
  next() {
    if (this.step == ReplaceStep.Done) {
      this.save();
      return;
    }
    let p;
    if (this.step == ReplaceStep.Init) {
      p = this.startManualReplace();
    } else if (this.step == ReplaceStep.Replace) {
      const inList = this.inList.filter(v => v.isIrrelevant == false);
      const outList = this.outList.filter(v => v.isIrrelevant == false);
      // if (inList.length != outList.length) {
      //   Toast.fail("还入电池数量和取出电池数量不一致");
      //   return;
      // }
      this.inList = inList;
      this.outList = outList;
      p = Promise.resolve();
    } else {
      p = Promise.resolve();
    }
    return p
      .then(() => ++this.step)
      .then(step => {
        if (step == ReplaceStep.Replace) {
          this.keepUpdating = true;
        } else {
          this.keepUpdating = false;
        }
      });
  }
  @Watch("keepUpdating")
  onKeepUpdatingChange(keepUpdating: boolean) {
    if (keepUpdating) {
      this.getUpdate();
    }
  }
  showCabinetPopup(act: CabinetAct) {
    this.cabinetPopup.act = act;
    this.cabinetPopup.show = true;
    this.getCabinetData();
  }
  onRoomPick() {
    const room = this.cabinetPopup.picked;
    switch (this.cabinetPopup.act) {
      case CabinetAct.Unlock:
        if (room.battery) {
          if (room.battery.replaceOrderId != 0) {
            Toast.fail("电池上仍有尚未结束的换电订单");
            return;
          }
          if (room.battery.userId != 0 && room.battery.userId != this.userId) {
            Toast.fail("禁止取出其他用户电池, 请先确认用户电池情况并处理");
            return;
          }
        }
        this.$axios.post("/api/manage/unlockRoomForReplaceManual", { replaceManualLogId: this.manualLogId, no: room.no }).then(() => {
          Toast.success("指令下发成功");
        });
        break;
      case CabinetAct.BatteryReturned:
        if (!room.battery) {
          Toast.fail("该仓内没有电池");
          return;
        }
        this.inList.push({
          act: 5,
          battery: room.battery,
          no: room.no,
          user: room.user,
          isIrrelevant: false
        });
        break;
    }
    this.cabinetPopup.show = false;
  }
  onStepChange(step: number) {
    const swiper = this.$refs.swiper as Swipe;
    swiper.swipeTo(step);
  }
  getCabinetActMsg() {
    switch (this.cabinetPopup.act) {
      case CabinetAct.Unlock:
        return "解锁";
      case CabinetAct.BatteryReturned:
        return "已归还";
      default:
        return "未知";
    }
  }
  // -------------- view-room-list
  getMsgColor(room: RoomState) {
    if (!room || room.roomId == 0) {
      return Color.Red;
    }
    if (!room.isConnect) {
      return Color.Black;
    }
    if (room.chargeState == 5) {
      return Color.Green;
    } else if (room.chargeState > 0 && room.chargeState < 5) {
      return Color.Orange;
    } else {
      return Color.Purple;
    }
  }
  getMsg(room: RoomState) {
    if (!room.roomId) return "未绑定";
    if (!room.isConnect) return "无电池";
    return room.chargeStateMsg;
  }
  getSubMsg(room: RoomState) {
    if (!room) return "  ";
    let subMsg = room.uMsg + "   " + room.iMsg;
    const batteryHasTask = room.battery && room.battery.taskId > 0;
    const taskPriority = room.task ? room.task.priority : 0;
    if (taskPriority > 0 || batteryHasTask) {
      const m = [];
      const p = ["低", "中", "高", "特高"];
      if (taskPriority > 0) {
        m.push(p[taskPriority - 1] + "任务");
      }
      if (batteryHasTask) {
        m.push("电池任务");
      }
      subMsg = m.join("|");
    }
    return subMsg;
  }
  getTempColor(room: RoomState) {
    return room && room.temp > 60 ? Color.Red : Color.Green;
  }
  getSoc(room: RoomState) {
    return room.batterySoc + "%";
  }
  getSocColor(room: RoomState) {
    return room.batterySoc && room.batterySoc > 85 ? Color.Green : Color.Red;
  }
  getLockStateColor(room: RoomState) {
    return room && room.lockState == 1 ? Color.Green : Color.Red;
  }
  getTopMidMsg(room: RoomState, cabinet: BatteryCabinet) {
    if (!room) return "";
    const isBatteryMisplace = room.battery && room.battery.userId > 0;
    if (isBatteryMisplace) return "用户电池";
    if (room.timeOut) return "仓离线";
    if (cabinet.cabinetType != 2 && room.batteryState && room.batteryState.timeOut) return "电池离线";
    return room.chargeTimeMsg;
  }
  getTopMidMsgColor(room: RoomState, cabinet: BatteryCabinet) {
    if (!room) return Color.Red;
    const isBatteryMisplace = room.battery && room.battery.userId > 0;
    return isBatteryMisplace || room.timeOut || (cabinet.cabinetType != 2 && room.batteryState && room.batteryState.timeOut) ? Color.Red : this.getMsgColor(room);
  }
}
